import { findClosestIndex } from "find-closest";
import Image, { ImageProps, ImageLoader } from "next/image";
import { FILE_API_URL, IMAGE_SIZE } from "common/constants";

export type ImgProps = ImageProps;

const getImageSize = (width: number) => {
  return Object.keys(IMAGE_SIZE)[
    findClosestIndex(Object.values(IMAGE_SIZE), width)
  ];
};

const loader: ImageLoader = ({ width, src }) => {
  return `${FILE_API_URL}/${getImageSize(width)}/${src}`;
};

const Img = (props: ImgProps) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image loader={loader} {...props} />;
};

export default Img;
