import { gql } from "@apollo/client";

export const TOURNAMENT_MATCH_FRAGMENT = gql`
  fragment TournamentMatchParts on TournamentMatch {
    id
    dateStart
    dateEnd
    teamIdA
    teamIdB
    teamA {
      id
      name
      contact
      logo
      playersUserData {
        id
        nickname
        profilePicture
      }
    }
    teamB {
      id
      name
      contact
      logo
      playersUserData {
        id
        nickname
        profilePicture
      }
    }
    teamScoreA
    teamScoreB
    confirmed
    twitchLink
    embed
    printScreen
    disputeScoreA
    disputeScoreB
    disputeBy
    disputePrintScreen
    bucket
    siblingMatchId
    childMatchId
    stageId
    loserMatchId
    round
    active
    note
    tournamentId
    fortunaLink
    tournament {
      gameId
      name
      game {
        coverImage
      }
    }
    stage {
      name
    }
    rounds {
      id
      matchId
      teamScoreA
      teamScoreB
      mapId
      map {
        id
        name
        image
      }
    }
  }
`;

export const USER_TOURNAMENT_MATCHES_QUERY = gql`
  query UserTournamentMatches(
    $offset: Int
    $limit: Int
    $orderBy: [TournamentMatchOrderByInput!]
  ) {
    userTournamentMatches(offset: $offset, limit: $limit, orderBy: $orderBy) {
      data {
        id
        dateStart
        note
        teamA {
          id
          name
          contact
          players
          ownerId
        }
        teamB {
          id
          name
          contact
          players
          ownerId
        }
        teamIdB
        teamIdA
        teamScoreA
        resultBy
        teamScoreB
        confirmed
        disputeBy
        disputeScoreA
        disputeScoreB
        printScreen
        stageId
        twitchLink
        fortunaLink
        embed
        round
        rounds {
          id
          matchId
          teamScoreA
          teamScoreB
          mapId
          map {
            id
            name
            image
          }
        }
        tournamentId
        tournament {
          gameId
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const TOURNAMENT_MATCH_QUERY = gql`
  query TournamentMatch($where: TournamentMatchWhereInput!) {
    tournamentMatch(where: $where) {
      ...TournamentMatchParts
    }
  }
  ${TOURNAMENT_MATCH_FRAGMENT}
`;

export const TOURNAMENT_MATCHES_QUERY = gql`
  query TournamentMatches(
    $where: TournamentMatchWhereInput
    $offset: Int
    $limit: Int
    $orderBy: [TournamentMatchOrderByInput!]
  ) {
    tournamentMatches(
      where: $where
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      data {
        ...TournamentMatchParts
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${TOURNAMENT_MATCH_FRAGMENT}
`;

export const TOURNAMENT_MATCH_FORM_QUERY = gql`
  query TournamentMatchForm(
    $tournamentTeamsWhere: TournamentTeamWhereInput
    $tournamentTeamsOffset: Int
    $tournamentTeamsLimit: Int
    $gameMapsWhere: GameMapWhereInput
    $gameMapsOffset: Int
    $gameMapsLimit: Int
  ) {
    tournamentTeams(
      where: $tournamentTeamsWhere
      offset: $tournamentTeamsOffset
      limit: $tournamentTeamsLimit
    ) {
      data {
        id
        name
      }
      pageInfo {
        totalCount
      }
    }
    gameMaps(
      where: $gameMapsWhere
      offset: $gameMapsOffset
      limit: $gameMapsLimit
    ) {
      data {
        id
        name
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const TOURNAMENT_MATCH_SPECIAL_BUCKETS_QUERY = gql`
  query TournamentMatchSpecialBuckets {
    tournamentMatchSpecialBuckets
  }
`;

export const UPCOMING_TOURNAMENT_MATCHES = gql`
  query UpcomingTournamentMatches(
    $limit: Int
    $gameId: Int
    $hoursOffset: Int
    $searchString: String
    $offset: Int
  ) {
    upcomingMatches(
      offset: $offset
      limit: $limit
      hoursOffset: $hoursOffset
      gameId: $gameId
      searchString: $searchString
    ) {
      data {
        ...TournamentMatchParts
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${TOURNAMENT_MATCH_FRAGMENT}
`;

export const CREATE_TOURNAMENT_MATCH_MUTATION = gql`
  mutation CreateTournamentMatch(
    $tournamentId: Int!
    $data: TournamentMatchInput!
  ) {
    createTournamentMatch(tournamentId: $tournamentId, data: $data) {
      id
    }
  }
`;

export const DELETE_TOURNAMENT_MATCH_MUTATION = gql`
  mutation DeleteTournamentMatch($where: TournamentMatchWhereUniqueInput!) {
    deleteTournamentMatch(where: $where) {
      id
    }
  }
`;

export const UPDATE_TOURNAMENT_MATCH_ROUND_DATE_MUTATION = gql`
  mutation UpdateTournamentMatchRoundDate(
    $data: TournamentMatchRoundDateInput!
  ) {
    updateTournamentMatchRoundDate(data: $data)
  }
`;

export const UPDATE_TOURNAMENT_MATCH_MUTATION = gql`
  mutation UpdateTournamentMatch(
    $where: TournamentMatchWhereUniqueInput!
    $data: TournamentMatchInput!
  ) {
    updateTournamentMatch(where: $where, data: $data) {
      id
    }
  }
`;

export const RESET_TOURNAMENT_MATCH_MUTATION = gql`
  mutation ResetTournamentMatch($where: TournamentMatchWhereUniqueInput!) {
    resetTournamentMatch(where: $where) {
      id
    }
  }
`;

export const GENERATE_TOURNAMENT_MATCH_GROUPS_MUTATION = gql`
  mutation GenerateTournamentMatchGroups(
    $data: GenerateTournamentMatchGroupsInput!
  ) {
    generateTournamentMatchGroups(data: $data)
  }
`;

export const GENERATE_TOURNAMENT_MATCH_BRACKETS_MUTATION = gql`
  mutation GenerateTournamentMatchBrackets(
    $tournamentId: Int!
    $stageId: Int!
    $noPreFill: Boolean = false
  ) {
    generateTournamentMatchBrackets(
      tournamentId: $tournamentId
      stageId: $stageId
      noPreFill: $noPreFill
    )
  }
`;

export const PROPOSE_TOURNAMENT_MATCH_SCORE_MUTATION = gql`
  mutation ProposeTournamentMatchScore(
    $where: TournamentMatchWhereUniqueInput!
    $data: TournamentMatchInput!
  ) {
    proposeTournamentMatchScore(where: $where, data: $data) {
      id
    }
  }
`;

export const DISPUTE_TOURNAMENT_MATCH_SCORE_MUTATION = gql`
  mutation DisputeTournamentMatchScore(
    $where: TournamentMatchWhereUniqueInput!
    $data: TournamentMatchScoreDisputeInput!
  ) {
    disputeTournamentMatchScore(where: $where, data: $data) {
      id
    }
  }
`;

export const CONFIRM_TOURNAMENT_MATCH_SCORE_MUTATION = gql`
  mutation ConfirmTournamentMatchScore(
    $where: TournamentMatchWhereUniqueInput!
  ) {
    confirmTournamentMatchScore(where: $where) {
      id
    }
  }
`;
