import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import Button from "base-components/Button";
import IconText from "base-components/IconText";
import { LoadingIndicatorInline } from "base-components/LoadingIndicator";
import AnchorLink from "components/AnchorLink";
import TournamentMatch from "components/TournamentMatch";
import { UPCOMING_TOURNAMENT_MATCHES } from "graphql/tournamentMatch";
import {
  UpcomingTournamentMatches,
  UpcomingTournamentMatchesVariables,
} from "types/graphql";
import styles from "./Matchbook.module.scss";

const Matchbook = () => {
  const { data, error, loading } = useQuery<
    UpcomingTournamentMatches,
    UpcomingTournamentMatchesVariables
  >(UPCOMING_TOURNAMENT_MATCHES, {
    variables: {
      hoursOffset: 3,
      limit: 4,
    },
  });
  const upcomingMatches = data?.upcomingMatches?.data ?? [];

  return (
    <section className={styles.matchbook}>
      <IconText as="h3" className={styles.matchbookTitle} icon="dice">
        <FormattedMessage defaultMessage="Upcoming matches" />
      </IconText>
      {loading ? (
        <LoadingIndicatorInline error={error} />
      ) : (
        <div className={styles.matchbookMatches}>
          {upcomingMatches.map((match) => (
            <TournamentMatch key={`match-${match.id}`} match={match} />
          ))}
        </div>
      )}
      <AnchorLink href="/tournaments/matches" className={styles.matchbookLink}>
        <Button backgroundColor="cool-gray-800" htmlType="button">
          <FormattedMessage
            defaultMessage="Show all"
            description="Show all matches"
          />
        </Button>
      </AnchorLink>
    </section>
  );
};

export default Matchbook;
