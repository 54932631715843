import styles from "./TournamentMatchFooter.module.scss";

type TournamentMatchFooterProps = {
  fortunaLink?: string | null;
};

const TournamentMatchFooter = ({ fortunaLink }: TournamentMatchFooterProps) => {
  if (!fortunaLink) {
    return null;
  }

  return (
    <a href={fortunaLink ?? ""} target="__blank" className={styles.container}>
      <picture>
        <source srcSet="/img/fortuna-logo.png" media="(min-width: 48rem)" />
        <img
          className={styles.banner}
          alt="Tipos"
          src="/img/fortuna-logo.png"
        />
      </picture>
    </a>
  );
};

export default TournamentMatchFooter;
