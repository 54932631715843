import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useRef, useState } from "react";
import Button from "base-components/Button";
import { LoadingIndicatorInline } from "base-components/LoadingIndicator";
import { addCustomScriptToHead } from "common/utils";
import AnchorLink from "components/AnchorLink";
import styles from "./TwitchPopup.module.scss";

const PLAYER_DIV_ID = "twitch-popup-player";

type TwitchPopupProps = {
  channel: string;
};

const TwitchPopup: FC<TwitchPopupProps> = ({ channel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    dialogRef.current?.toggleAttribute("open", isOpen);
  }, [isOpen]);

  useEffect(() => {
    addCustomScriptToHead(
      "https://player.twitch.tv/js/embed/v1.js",
      "twitch-embed-script",
      () => {
        if ((window as any).Twitch) {
          const playerDiv = document.getElementById(PLAYER_DIV_ID);
          if (playerDiv) {
            playerDiv.innerHTML = "";
          }
          const options = {
            width: "100%",
            height: "100%",
            channel,
          };
          const player = new Twitch.Player(PLAYER_DIV_ID, options);
          player.setMuted(true);
          player.addEventListener(Twitch.Player.ONLINE, () => {
            setIsOpen(true);
          });
          player.addEventListener(Twitch.Player.OFFLINE, () => {
            setIsOpen(false);
          });
        }
      }
    );
  }, [channel]);

  return (
    <dialog className={styles.twitchPopup} ref={dialogRef}>
      <Button
        icon={["fab", "twitch"]}
        className={styles.twitchPopupButton}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <span className={styles.twitchPopupTopBar}>
        Live stream
        <AnchorLink href={`https://twitch.tv/${channel}`}>
          <FontAwesomeIcon
            icon={["fab", "twitch"]}
            className={styles.twitchPopupTopBarIcon}
          />
          twitch.tv/{channel}
        </AnchorLink>
      </span>
      <div id={PLAYER_DIV_ID}>
        <LoadingIndicatorInline />
      </div>
    </dialog>
  );
};

export default TwitchPopup;
