import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import AnchorLink from "components/AnchorLink";
import Img from "components/Img";
import TournamentMatchFooter from "components/TournamentMatchFooter/TournamentMatchFooter";
import { UpcomingTournamentMatches_upcomingMatches_data } from "types/graphql";
import styles from "./TournamentMatch.module.scss";

type TournamentMatchProps = {
  match: UpcomingTournamentMatches_upcomingMatches_data;
};

const TournamentMatch = ({ match }: TournamentMatchProps) => {
  const formattedDate = new Date(match.dateStart);
  const startTime = formattedDate.toLocaleTimeString("sk", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const startDate = formattedDate.toLocaleDateString("sk", {
    day: "2-digit",
    month: "2-digit",
  });

  const teamALogo = match.teamA?.logo;
  const teamBLogo = match.teamB?.logo;
  const twitchLink = match.twitchLink?.startsWith("https://")
    ? match.twitchLink
    : `https://${match.twitchLink}`;

  const displayResults = useMemo(
    () =>
      moment().isAfter(match.dateStart) &&
      (match.teamScoreA || match.teamScoreB),
    [match.teamScoreA, match.teamScoreB, match.dateStart]
  );

  return (
    <article className={styles.tournamentMatch}>
      <AnchorLink
        href={`/tournaments/${match.tournamentId}/matches/${match.id}`}
        className={styles.tournamentMatchLink}
      >
        <div className={styles.tournamentMatchHeader}>
          <div>
            <AnchorLink
              href={`tournaments/${match.tournamentId}`}
              className={styles.tournamentMatchHeaderLink}
            >
              <h5>{match.tournament.name}</h5>
            </AnchorLink>
            <h6>
              <FormattedMessage
                defaultMessage="Group { matchBucket }"
                values={{
                  matchBucket: match.bucket,
                }}
              />
            </h6>
          </div>
          {match.twitchLink && (
            <AnchorLink
              href={twitchLink}
              className={classNames(
                styles.tournamentMatchHeaderTwitchImage,
                styles.tournamentMatchImage
              )}
              onClick={(event) => event.stopPropagation()}
              target="__blank"
            >
              <FontAwesomeIcon
                icon={["fab", "twitch"]}
                color={`hsl(var(--color-white))`}
                size="xs"
              />
            </AnchorLink>
          )}
        </div>
      </AnchorLink>
      <div className={styles.tournamentMatchBody}>
        <article className={styles.tournamentMatchBodyGame}>
          <div className={styles.tournamentMatchImage}>
            <Img
              src={match.tournament.game?.coverImage ?? ""}
              objectFit="contain"
              layout="fill"
              sizes="50vw"
            />
          </div>
        </article>
        <article className={styles.tournamentMatchBodyTeams}>
          <div className={styles.tournamentMatchBodyTeamsTeam}>
            <div
              className={classNames(styles.tournamentMatchImage, {
                [styles.tournamentMatchImageIcon]: !teamALogo,
              })}
            >
              {teamALogo ? (
                <Img
                  src={teamALogo ?? ""}
                  objectFit="contain"
                  layout="fill"
                  sizes="50vw"
                />
              ) : (
                <FontAwesomeIcon
                  icon="user"
                  size="1x"
                  color={`hsl(var(--color-white))`}
                />
              )}
            </div>
            <span className={styles.tournamentMatchBodyTeamsTeamName}>
              {match.teamA?.name}
            </span>
          </div>
          <div className={styles.tournamentMatchBodyTeamsTeam}>
            <div
              className={classNames(styles.tournamentMatchImage, {
                [styles.tournamentMatchImageIcon]: !teamBLogo,
              })}
            >
              {teamBLogo ? (
                <Img
                  src={teamBLogo ?? ""}
                  objectFit="contain"
                  layout="fill"
                  sizes="50vw"
                />
              ) : (
                <FontAwesomeIcon
                  icon="user"
                  size="1x"
                  color={`hsl(var(--color-white))`}
                />
              )}
            </div>
            <span className={styles.tournamentMatchBodyTeamsTeamName}>
              {match.teamB?.name}
            </span>
          </div>
        </article>
        {displayResults ? (
          <article className={styles.tournamentMatchBodyTeamsScore}>
            <div>{match.teamScoreA}</div> <div>{match.teamScoreB}</div>
          </article>
        ) : (
          <article className={styles.tournamentMatchBodyTeamsStartDate}>
            {startDate} {startTime}
          </article>
        )}
      </div>
      <TournamentMatchFooter fortunaLink={match.fortunaLink} />
    </article>
  );
};

export default TournamentMatch;
