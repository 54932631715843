import { FormattedMessage } from "react-intl";
import Button from "base-components/Button";
import ArticleList from "components/ArticleList";
import {
  ArticleListProps,
  TemplateName,
} from "components/ArticleList/ArticleList";
import Banner from "components/Banner/Banner";
import Matchbook from "components/Matchbook";
import TwitchPopup from "components/TwitchPopup";
import { BannerType } from "types/graphql";
import styles from "./Sidebar.module.scss";

export type SidebarProps<Template extends TemplateName> = {
  articleTemplates?: ArticleListProps<Template>[];
  twitchChannel?: string;
};

const Sidebar = <Template extends TemplateName>({
  articleTemplates,
  twitchChannel,
}: SidebarProps<Template>) => (
  <aside className={styles.sidebar}>
    <Banner type={BannerType.SIDEBAR} />
    {twitchChannel && <TwitchPopup channel={twitchChannel} />}
    <article className={styles.discord}>
      <h4 data-no-margin>
        <FormattedMessage
          defaultMessage="Join us on Discord"
          description="Discord server join link"
        />
      </h4>
      <a
        className={styles.discordLink}
        href="https://discord.gg/ygames-432799253090402305"
        target="__blank"
      >
        <Button
          className={styles.discordLinkButton}
          type="neutral"
          icon={["fab", "discord"]}
        >
          Discord
        </Button>
      </a>
    </article>
    <Matchbook />
    {articleTemplates?.map((props) => (
      <ArticleList key={`sidebar-articles-${props.template}`} {...props} />
    ))}
  </aside>
);

export default Sidebar;
